const getTokensForExistingSession = async oktaAuth =>
  (await oktaAuth.token.getWithoutPrompt())?.tokens;

const setTokens = (oktaAuth, tokens) => { oktaAuth.tokenManager.setTokens(tokens); };
const loginRequired = e => e.errorCode === 'login_required' && e.name === 'OAuthError';

export const fetchAndSetTokensForExistingSession = async (oktaAuth) => {
  let tokens = null;
  try {
    tokens = await getTokensForExistingSession(oktaAuth);
    setTokens(oktaAuth, tokens);
  } catch (e) {
    if (!loginRequired(e)) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
  return tokens;
};

export const userIsAuthenticated = async authState => authState?.isAuthenticated;
