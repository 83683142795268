import * as React from 'react';
import { useEffect, useRef } from 'react';

import qs from 'qs';
import OktaSignIn from '@okta/okta-signin-widget';
import { useOktaAuth } from '@okta/okta-react';
import { Tokens } from '@okta/okta-auth-js';

import { OktaStore } from '../../../core/utils/okta';
import History from '../../../utils/history';
import Token from '../../../utils/token';

interface LoginWidgetProps {
  config: Record<string, unknown>;
  className?: string;
}

export const LoginWidget = ({ config, className }: LoginWidgetProps): JSX.Element => {
  const { oktaAuth, authState } = useOktaAuth();
  const widgetRef = useRef<HTMLDivElement>();
  const widget = new OktaSignIn(config);
  const { search } = History.location;
  const { redirect, ...params } = qs.parse(search, { ignoreQueryPrefix: true });
  const { referrer } = document;
  const path = qs.stringify(params) !== '' ? `${redirect}&${qs.stringify(params)}` : redirect;
  const wl = [/^\w.*?\.fpint\.net/, /^\w.*?\.?fp.tools/];
  const ext = wl.some(v => v.test(path)) ? `https://${redirect}` : '';
  const rdr = /^\/home\//.test(redirect) ? redirect : '';
  const pth = /^\/home\//.test(path) ? path : '';

  const saml = /saml/ig.test(config.redirectUri);

  useEffect(() => {
    // If your session cookie (which contains an auth token) is invalid,
    // then remove the same auth information from localStorage and start fresh
    if (!saml && !Token.cke()) {
      OktaStore.removeItem('okta-token-storage');
      OktaStore.removeItem('okta-cache-storage');
      OktaStore.removeItem('apiAccessToken');
    }

    if (!widgetRef.current) {
      return () => widget.remove();
    }

    // Handle OIDC login
    widget.showSignInToGetTokens({
      el: widgetRef.current,
    }).then((tokens: Tokens) => {
      oktaAuth.handleLoginRedirect(tokens);
    }).catch((err: Error) => {
      throw err;
    });

    return () => widget.remove();
  }, []);

  useEffect(() => {
    const marketing = `//go.flashpoint-intel.com/trial/access/rbs-vulndb/flashpoint-intelligence-platform?redirect=${redirect}`;
    const vulndb = /vulndb\./.test(referrer);
    if (saml) return;
    if (!authState.isAuthenticated && vulndb) window.location.href = marketing;
  }, [referrer]);

  useEffect(() => {
    if (!authState.isAuthenticated) return;
    window.location.href = ext || pth || rdr || '/home';
  }, [authState.isAuthenticated]);

  return <div ref={widgetRef} className={className} />;
};

LoginWidget.defaultProps = {
  className: '',
};
