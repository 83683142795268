import { OktaStore } from './OktaStore';
import FpLogo from '../../../../public/images/okta-fp-logo.svg';

const RedirectURI = `https://${window.location.host}`;

const FPTOOLS_OKTA_CLIENT_ID =
  // '0oa4ls56wfS3mblX65d7'; // fptools-staging
  // '0oa4ltobvz6lDnnZM5d7'; // fptools-dev
  '0oa5a4k39jdB5xsRn5d6'; // fptools-prod
const SAML_BASE_URL = 'https://flashpoint.okta.com';
const APP_EMBED_LINK = `${SAML_BASE_URL}/home/oidc_client/${FPTOOLS_OKTA_CLIENT_ID}/aln177a159h7Zf52X0g8`;

const SamlConfigDev = {
  baseUrl: SAML_BASE_URL,
  clientId: FPTOOLS_OKTA_CLIENT_ID,
  issuer: `${SAML_BASE_URL}/oauth2/default`,
  redirectUri: `${RedirectURI}/saml`,
  idpDisplay: 'SECONDARY',
  authParams: {
    scopes: ['openid', 'profile', 'email'],
  },
  pkce: true,
  logo: FpLogo,
  storage: {
    token: {
      storageProvider: OktaStore,
    },
  },
  tokenManager: {
    storage: OktaStore,
  },
  features: {
    idpDiscovery: true,
  },
  idpDiscovery: {
    requestContext: APP_EMBED_LINK,
  },
  i18n: {
    en: {
      'primaryauth.username.placeholder': 'Email or username',
    },
  },
  onSessionExpired: (): null => null,
};

const SamlConfigRc = {
  ...SamlConfigDev,
};

const SamlConfigProd = {
  ...SamlConfigDev,
};

const SsoConfigDev = {
  baseUrl: 'https://login.flashpoint.io',
  clientId: '0oa5a4k39jdB5xsRn5d6',
  redirectUri: `${RedirectURI}/login/callback`,
  issuer: 'https://login.flashpoint.io/oauth2/default',
  idpDisplay: 'SECONDARY',
  authParams: {
    scopes: ['openid', 'profile', 'email', 'offline_access'],
  },
  pkce: true,
  logo: FpLogo,
  storage: {
    token: {
      storageProvider: OktaStore,
    },
  },
  tokenManager: {
    storage: OktaStore,
  },
  idps: [{ type: 'Google', id: '0oa3ckx2aCbC098Ta5d6' }],
  customButtons: [
    {
      title: 'Log in with SAML',
      className: 'fp-btn-saml',
      click: () => {
        window.location.href = '/saml';
      },
    },
  ],
  i18n: {
    en: {
      'primaryauth.username.placeholder': 'Email or username',
    },
  },
};

const SsoConfigRc = {
  ...SsoConfigDev,
};

const SsoConfigProd = {
  ...SsoConfigDev,
};

const _SamlConfig = /localhost/gi.test(window.location.host) ? SamlConfigDev : SamlConfigProd;
const SamlConfig = /rc.fp.tools/gi.test(window.location.host) ? SamlConfigRc : _SamlConfig;

const _SsoConfig = /localhost/gi.test(window.location.host) ? SsoConfigDev : SsoConfigProd;
const SsoConfig = /rc.fp.tools/gi.test(window.location.host) ? SsoConfigRc : _SsoConfig;

export { SsoConfig, SamlConfig };
