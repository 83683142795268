import { OktaAuth, SessionObject } from '@okta/okta-auth-js';
import OktaStore from './OktaStore';

import { SsoConfig, SamlConfig } from './Config';

const isLoginPage = () => !/home/gi.test(window.location.href);
const isSamlPage = () => /saml/gi.test(window.location.href);
const storedAccessToken = () => JSON.parse(OktaStore.getItem('okta-token-storage'))?.accessToken;
const storedTokenIssuer = () => storedAccessToken()?.claims?.iss;
const accessTokenExists = () => !!storedAccessToken();

const tokenIssuerBaseUrl = () => {
  try {
    return storedTokenIssuer().replace('/oauth2/default', '');
  } catch (e) {
    return null;
  }
};

const chooseAuthManagerConfig = () => {
  if (isLoginPage()) {
    return isSamlPage() ? SamlConfig : SsoConfig;
  } else if (accessTokenExists()) {
    // Choose config based on the token's issuer
    return tokenIssuerBaseUrl() === SamlConfig.baseUrl ? SamlConfig : SsoConfig;
  }
  return SsoConfig;
};

const ApiConfig = {
  ...SsoConfig,
  tokenManager: {
    storage: OktaStore,
    storageKey: 'apiAccessToken',
  },
  // It is important to use the issuer that matches the URL of the issuer of the existing access token in localStorage
  // because authorizeApi uses this ApiConfig to get a fresh token using getWithoutPrompt, which only works if the user
  // has an open session (an SID cookie) on the okta domain that the user just recently logged in to
  // That is, if the user logged into login.flashpoint.io, then authorizeApi cannot try to get an access token from flashpoint.okta.com (and vice versa)
  issuer: tokenIssuerBaseUrl() || 'https://login.flashpoint.io',
};

let AuthManager: OktaAuth;
let ApiManager: OktaAuth;

export const Okta = {
  authorizeApi: async (): Promise<void> => {
    if (!ApiManager) {
      ApiManager = new OktaAuth(ApiConfig);
    }

    let generateToken = false;
    const apiAccessToken = OktaStore.getItem('apiAccessToken');
    const expiresAt = apiAccessToken ? JSON.parse(apiAccessToken).expiresAt : null;
    if (!expiresAt || expiresAt < new Date().getTime() / 1000) {
      generateToken = true;
    }

    if (generateToken) {
      ApiManager.token
        .getWithoutPrompt({
          scopes: ['okta.users.manage'],
          responseType: ['token'],
        })
        .then((res) => {
          OktaStore.setItem('apiAccessToken', JSON.stringify(res.tokens.accessToken));
        });
    }
  },
  getAuthManager: (): OktaAuth => {
    if (!AuthManager) {
      AuthManager = new OktaAuth(chooseAuthManagerConfig());
    }
    return AuthManager;
  },
  getIdToken: (): string => {
    const data = OktaStore.getItem('okta-token-storage');
    const oktaData = JSON.parse(data);
    return oktaData?.idToken?.value;
  },
  isFpLogin: (): boolean => {
    const FlashpointIdp = '00o2sl9rcCkwazW3K5d6';
    const data = OktaStore.getItem('okta-token-storage');
    if (!data) {
      return false;
    }
    const oktaData = JSON.parse(data);
    return oktaData?.idToken?.claims?.idp === FlashpointIdp;
  },
  mintIdToken: async (): Promise<void> => {
    const authManager = Okta.getAuthManager();
    const session: SessionObject = await authManager.session.get();
    if (session.status !== 'ACTIVE') {
      return;
    }
    authManager.token
      .getWithoutPrompt({
        responseType: 'id_token',
      })
      .then((res) => {
        authManager.tokenManager.setTokens(res.tokens);
      });
  },
};
