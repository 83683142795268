/* eslint-disable security/detect-non-literal-regexp */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable no-param-reassign */

function widgetState() {
  const statefulClassName = window.location.href.replace(new RegExp(`${window.location.origin}/?`), '').replace('/', '-').trim();
  return statefulClassName.length > 0 ? statefulClassName : 'login-start-page';
}

const setClassForUrlState = function setClassForUrlState() {
  const myBody = document.querySelector('.auth.okta-container');
  myBody.className = 'auth okta-container';
  const statefulClassName = widgetState();
  myBody.classList.add(statefulClassName);
};

const transformAuthDividerText = function transformAuthDividerText() {
  const orText = document.querySelector('#okta-sign-in.auth-container.main-container  .auth-divider-text');
  if (orText) {
    orText.innerText = 'Log in with';
  }
};

const transformIdpDiscoveryButtonText = function transformIdpDiscoveryButtonText() {
  const idpDiscoveryButton = document.querySelector('.login-start-page #okta-sign-in.auth-container.main-container .o-form-button-bar .button.button-primary');
  if (idpDiscoveryButton) {
    idpDiscoveryButton.value = 'Log In';
  }
};

const transformUnlockEmailButtonText = function transformUnlockEmailButtonText() {
  const emailButton = document.querySelector('.auth.okta-container.signin-unlock .o-form-fieldset-container .button.button-primary.email-button');
  if (emailButton) {
    emailButton.innerText = 'Send Unlock Request';
  }
};

const transformGoogleButton = function transformGoogleButton() {
  const googleButton = document.querySelector('#okta-sign-in.auth-container.main-container .social-auth-button.social-auth-google-button');
  if (!googleButton) {
    return;
  }
  googleButton.innerHTML = '\n    <svg width="17.5" height="17.5" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n      <path d="M14.8638 6.62905H14.3V6.6H8V9.4H11.956C11.3789 11.03 9.82805 12.2 8 12.2C5.68055 12.2 3.8 10.3194 3.8 8C3.8 5.68055 5.68055 3.8 8 3.8C9.07065 3.8 10.0447 4.2039 10.7864 4.86365L12.7663 2.8837C11.5161 1.71855 9.8438 1 8 1C4.13425 1 1 4.13425 1 8C1 11.8658 4.13425 15 8 15C11.8658 15 15 11.8658 15 8C15 7.53065 14.9517 7.0725 14.8638 6.62905Z" fill="#FFC107"/>\n      <path d="M1.8071 4.74185L4.10695 6.4285C4.72925 4.8878 6.23635 3.8 8 3.8C9.07065 3.8 10.0447 4.2039 10.7864 4.86365L12.7663 2.8837C11.5161 1.71855 9.8438 1 8 1C5.3113 1 2.9796 2.51795 1.8071 4.74185Z" fill="#FF3D00"/>\n      <path d="M8 15C9.8081 15 11.451 14.308 12.6931 13.1828L10.5266 11.3495C9.82385 11.8818 8.95025 12.2 8 12.2C6.1793 12.2 4.63335 11.0391 4.05095 9.4189L1.76825 11.1776C2.92675 13.4446 5.27945 15 8 15Z" fill="#4CAF50"/>\n      <path d="M14.8638 6.62905H14.3V6.6H8V9.4H11.956C11.6789 10.183 11.1752 10.8581 10.5256 11.3498C10.5259 11.3495 10.5263 11.3498 10.5266 11.3495L12.6931 13.1828C12.5399 13.3221 15 11.5 15 8C15 7.53065 14.9517 7.0725 14.8638 6.62905Z" fill="#1976D2"/>\n    </svg>\n    Google\n  ';
};

const queryParentsThatContainChild = function queryParentsThatContainChild(parentQuery, childQuery) {
  const parents = document.querySelectorAll(parentQuery);
  return Array.prototype.slice.call(parents).filter(parent => parent.querySelector(childQuery));
};

const showPasswordButtonClickListener = function showPasswordButtonClickListener(event) {
  const showPasswordButton = event.target;
  const myPwInput = event.target.parentElement.parentElement.querySelector('input[type="password"]');
  const myVisibleInput = event.target.parentElement.parentElement.querySelector('.pw-text-input');

  if (showPasswordButton.innerText === 'Show') {
    myPwInput.style = 'display: none';
    myVisibleInput.style = 'display: block';
    myPwInput['show-hide'] = 'Hide';
    showPasswordButton.innerText = 'Hide';
  } else {
    myPwInput.style = 'display: block';
    myVisibleInput.style = 'display: none';
    myPwInput['show-hide'] = 'Show';
    showPasswordButton.innerText = 'Show';
  }
};

const passwordInputChangeListener = function passwordInputChangeListener(event) {
  const someInput = event.target;
  const myPwInput = someInput.parentElement.querySelector('input[type="password"]');
  const myVisibleInput = someInput.parentElement.querySelector('.pw-text-input');

  if (myPwInput['show-hide'] === 'Hide') {
    myPwInput.value = myVisibleInput.value;
  } else {
    myVisibleInput.value = myPwInput.value;
  }
};

const cleanlyAddListener = function cleanlyAddListener(node, name, func, opts) {
  try {
    node.removeEventListener(name, func, opts);
  } catch (e) {
    // do nothing
  }
  node.addEventListener(name, func, opts);
};

const addHiddenTextInputBeneathPwInput = function addHiddenTextInputBeneathPwInput(pwSpan) {
  const pwInput = pwSpan.querySelector('input[type="password"]');
  cleanlyAddListener(pwInput, 'change', passwordInputChangeListener);

  let visibleInput = pwSpan.querySelector('input.pw-text-input');
  if (!visibleInput) {
    visibleInput = document.createElement('input');
    pwInput.parentElement.appendChild(visibleInput);
    visibleInput.type = 'text';
    visibleInput.classList.add('pw-text-input');
    visibleInput.style = 'display: none;';
  }
  cleanlyAddListener(visibleInput, 'change', passwordInputChangeListener);
};

const addShowPasswordLabelAbovePwInput = function addShowPasswordLabelAbovePwInput(pwSpan) {
  if (pwSpan.querySelector('.showPasswordButton')) {
    return;
  }
  const showPasswordButton = document.createElement('div');
  showPasswordButton.classList.add('showPasswordButton');
  showPasswordButton.innerText = 'Show';
  showPasswordButton.addEventListener('click', showPasswordButtonClickListener);
  const passwordLabel = pwSpan.querySelector('label');
  passwordLabel.parentElement.appendChild(showPasswordButton);
};

const addShowPasswordButton = function addShowPasswordButton() {
  const pwSpans = queryParentsThatContainChild('.o-form-fieldset', 'input[type="password"]');
  pwSpans.forEach((pwSpan) => {
    addShowPasswordLabelAbovePwInput(pwSpan);
    addHiddenTextInputBeneathPwInput(pwSpan);
  });
};

const fixEmailMargin = function fixEmailMargin() {
  const emailEntryContainer = document.querySelector('#okta-sign-in.auth-container.main-container .o-form-fieldset.o-form-label-top.margin-btm-5');
  if (!emailEntryContainer) {
    return;
  }
  emailEntryContainer.classList.remove('margin-btm-5');
  emailEntryContainer.classList.add('margin-btm-9');
};

const transformInfoboxHeader = function transformInfoboxHeader(infoboxDiv) {
  const headerDiv = infoboxDiv.querySelector('.infobox-header');
  if (!headerDiv) {
    const oldHtml = infoboxDiv.innerHTML;
    infoboxDiv.innerHTML = `<div class="infobox-header"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n      <path d="M8.00044 10.7857C7.63818 10.7857 7.36887 11.0734 7.36887 11.4286C7.36887 11.7835 7.66278 12.0714 8.00044 12.0714C8.3627 12.0714 8.632 11.7835 8.632 11.4286C8.6566 11.0723 8.36406 10.7857 8.00044 10.7857ZM8.00044 9.71429C8.24221 9.71429 8.41328 9.52261 8.41328 9.28571V5.42857C8.41328 5.19168 8.21766 5 8.00044 5C7.78322 5 7.56299 5.19286 7.56299 5.42857V9.28571C7.56299 9.52143 7.75984 9.71429 8.00044 9.71429ZM14.789 11.7848L9.32644 2.73339C9.0503 2.27509 8.55271 2.0008 8.00044 2C7.44816 2 6.9533 2.27375 6.67443 2.73286L1.2091 11.7864C0.934329 12.241 0.930228 12.7866 1.19757 13.2463C1.47403 13.7188 1.97326 14 2.5351 14H13.4685C14.0293 14 14.5279 13.7179 14.803 13.2454C15.0706 12.7866 15.0652 12.2402 14.789 11.7848ZM14.018 12.7973C13.9251 13.0223 13.7091 13.1429 13.4439 13.1429H2.5351C2.29161 13.1429 2.07633 13.0227 1.95877 12.8214C1.84728 12.6298 1.849 12.4116 1.96304 12.2225L7.42902 3.16946C7.54659 2.97393 7.75984 2.85714 8.00044 2.85714C8.00044 2.85714 8.00001 2.85714 8.00044 2.85714C8.24007 2.85756 8.45319 2.9739 8.57076 3.16893L14.0361 12.2225C14.1274 12.4116 14.1547 12.6286 14.018 12.7973Z" fill="#CB0025"/>\n      </svg><span class="infobox-header-text"></span></div>${oldHtml}`;
  }
};

const transformInfoboxErrorText = function transformInfoboxErrorText(infoboxDiv) {
  const headerTextSpan = infoboxDiv.querySelector('.infobox-header-text');
  if (infoboxDiv.innerHTML.includes('Unable to sign in')) {
    (function () {
      headerTextSpan.innerText = 'Invalid Credentials';
      infoboxDiv.innerHTML = infoboxDiv.innerHTML.replace('Unable to sign in', 'These credentials do not match our records. Please try again or <a class="error-msg-reset-password-link" href="#">reset your password</a>.');
      const resetPass = infoboxDiv.querySelector('.error-msg-reset-password-link');
      const lowerForgotPassword = document.querySelector('#okta-sign-in.auth-container.main-container .auth-footer .js-forgot-password');
      resetPass.addEventListener('click', (e) => {
        e.preventDefault();
        lowerForgotPassword.click();
      });
    }());
  } else if (infoboxDiv.innerHTML.includes('We found some errors. Please review the form and make corrections.')) {
    headerTextSpan.innerText = 'Invalid Credentials';
  } else if (infoboxDiv.innerHTML.includes('This password was found in a list of commonly used passwords. Please try another password.')) {
    headerTextSpan.innerText = 'Weak Password';
  } else if (infoboxDiv.innerHTML.includes('Your account is locked.')) {
    headerTextSpan.innerText = 'Your Account Has Been Temporarily Locked';
    infoboxDiv.innerHTML = infoboxDiv.innerHTML.replace('Your account is locked. Please contact your administrator.', 'Please check your email for instructions on how to access your account.');
  }
  const safeInnerText = headerTextSpan.innerText ? headerTextSpan.innerText : '';
  if (!safeInnerText.trim()) {
    headerTextSpan.innerText = 'Login Error';
  }
};

const transformInfoboxError = function transformInfoboxError() {
  const infoboxDiv = document.querySelector('.okta-form-infobox-error.infobox.infobox-error');
  if (!infoboxDiv) {
    return;
  }
  transformInfoboxHeader(infoboxDiv);
  transformInfoboxErrorText(infoboxDiv);
};

const addClassForErrorState = function addClassForErrorState() {
  const oFormContent = document.querySelector('.o-form-content');
  if (!oFormContent) {
    return;
  }
  const errorContainer = oFormContent.querySelector('.o-form-error-container.o-form-has-errors');
  if (errorContainer) {
    oFormContent.classList.add('form-has-errors');
  } else {
    oFormContent.classList.remove('form-has-errors');
  }
};

const addInputErrorStateIcon = function addInputErrorStateIcon() {
  const inputSpans = document.querySelectorAll('#okta-sign-in.auth-container.main-container .okta-form-input-field');
  inputSpans.forEach((inputSpan) => {
    const inputErrorIcon = inputSpan.querySelector('.input-error-icon');
    if (!inputErrorIcon) {
      inputSpan.innerHTML = `<svg class="input-error-icon" width="17.5" height="17.5" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n<path d="M8 1C4.13359 1 1 4.13359 1 8C1 11.8664 4.13359 15 8 15C11.8664 15 15 11.8664 15 8C15 4.13359 11.8664 1 8 1ZM8 14.125C4.62305 14.125 1.875 11.377 1.875 8C1.875 4.62305 4.62305 1.875 8 1.875C11.377 1.875 14.125 4.62305 14.125 8C14.125 11.377 11.377 14.125 8 14.125ZM8 9.3125C8.24183 9.3125 8.4375 9.11683 8.4375 8.875V4.5C8.4375 4.25817 8.24183 4.0625 8 4.0625C7.75817 4.0625 7.5625 4.25938 7.5625 4.5V8.875C7.5625 9.11563 7.75938 9.3125 8 9.3125ZM8 10.4062C7.6377 10.4062 7.34375 10.7002 7.34375 11.0625C7.34375 11.4248 7.6377 11.7188 8 11.7188C8.3623 11.7188 8.65625 11.4248 8.65625 11.0625C8.65625 10.7002 8.36367 10.4062 8 10.4062Z" fill="#CB0025"></path>\n</svg>${inputSpan.innerHTML}`;
    }
  });
};

/*
FORGOT PASSWORD STATE
*/
const fixEmailLabel = function fixEmailLabel() {
  const emailLabel = document.querySelector('#okta-sign-in.auth-container.main-container .o-form-label label[for="account-recovery-username"]');
  if (emailLabel) {
    emailLabel.innerText = 'Email or username';
  }
};

const transformBackLinkText = function transformBackLinkText() {
  const authFooter = document.querySelector('#okta-sign-in.auth-container.main-container .auth-footer');
  if (!authFooter) {
    return;
  }
  const backLinks = authFooter.querySelectorAll('a');
  backLinks.forEach((backLink) => {
    backLink.innerText = backLink.innerText.replace('Back to sign in', 'Back to log in');
  });
};

const happensToTheBestOfUs = function happensToTheBestOfUs() {
  let happensTo = document.querySelector('.happens-to-the-best-of-us');
  if (happensTo) {
    return;
  }
  const fieldsetContainer = document.querySelector('.signin-forgot-password #okta-sign-in.auth-container.main-container .o-form-fieldset-container');
  if (!fieldsetContainer) {
    return;
  }
  const fieldSet = fieldsetContainer.querySelector('.o-form-fieldset');
  happensTo = document.createElement('div');
  happensTo.innerText = 'Don\'t worry, happens to the best of us';
  happensTo.classList.add('happens-to-the-best-of-us');
  fieldsetContainer.insertBefore(happensTo, fieldSet);
};

const looksLikeYourAccountHasBeenLocked = function looksLikeYourAccountHasBeenLocked() {
  const oFormHead = document.querySelector('.auth.okta-container.signin-unlock #okta-sign-in.auth-container.main-container .okta-form-title.o-form-head');
  if (!oFormHead) {
    return;
  }
  oFormHead.innerText = 'Looks like your account has been locked. Not to worry, send a request to unlock your account.';
};

const unlockEmailHasBeenSent = function unlockEmailHasBeenSent() {
  const oFormExplain = document.querySelector('.auth.okta-container.signin-unlock-emailed #okta-sign-in.auth-container.main-container .okta-form-subtitle.o-form-explain');
  if (!oFormExplain) {
    return;
  }
  oFormExplain.innerHTML = 'An account unlock request was made for your Flashpoint account. If you didn’t make this request, please contact Flashpoint support immediately at <a href="mailto: support@flashpoint-intel.com">support@flashpoint-intel.com</a>.';
};

const recoveryEmailHasBeenSent = function recoveryEmailHasBeenSent() {
  const oFormExplain = document.querySelector('.auth.okta-container.signin-recovery-emailed #okta-sign-in.auth-container.main-container .okta-form-subtitle.o-form-explain');
  if (!oFormExplain) {
    return;
  }
  oFormExplain.innerText = oFormExplain.innerText.replace(/Email has been sent to (.+) with instructions on resetting your password./, 'If $1 is an active account, you will receive an email with instructions on resetting your password.');
};

const transformAccountUnlockRequested = function transformAccountUnlockRequested() {
  const oFormHead = document.querySelector('.auth.okta-container.signin-unlock-emailed #okta-sign-in.auth-container.main-container .okta-form-title.o-form-head');
  if (!oFormHead) {
    return;
  }
  oFormHead.innerText = 'Account Unlock Requested';
};

const expiresInThreeDays = function expiresInThreeDays() {
  const oFormContent = document.querySelector('.signin-unlock-emailed #okta-sign-in.auth-container.main-container .o-form-content');
  if (!oFormContent) {
    return;
  }
  let expiresInThree = oFormContent.querySelector('.expires-in-three');
  if (expiresInThree) {
    return;
  }
  expiresInThree = document.createElement('div');
  expiresInThree.innerText = 'This link expires in 3 days.';
  expiresInThree.classList.add('expires-in-three');
  oFormContent.appendChild(expiresInThree);
};

const transformLogoSubtext = function transformLogoSubtext() {
  const logoSubtext = document.querySelector('.auth.okta-container .signInToContinue');
  if (!logoSubtext) {
    return;
  }
  if (['signin-forgot-password', 'signin-recovery-emailed', 'signin-password-reset'].includes(widgetState())) {
    logoSubtext.innerText = 'Reset Password';
  } else if (['signin-unlock', 'signin-unlock-emailed'].includes(widgetState())) {
    logoSubtext.innerText = 'Request to Unlock Account';
  } else {
    logoSubtext.innerText = 'Log in to continue';
  }
};

const moveForgotPasswordLink = function moveForgotPasswordLink() {
  const lowerForgotPassword = document.querySelector('#okta-sign-in.auth-container.main-container .auth-footer .js-forgot-password');
  const oFormButtonBar = document.querySelector('#okta-sign-in.auth-container.main-container .o-form-button-bar');
  if (!lowerForgotPassword || !oFormButtonBar) {
    return;
  }
  let upperForgotPassword = oFormButtonBar.querySelector('.link.js-forgot-password');
  if (upperForgotPassword) {
    return;
  }
  upperForgotPassword = document.createElement('div');
  upperForgotPassword.innerHTML = '<a href="#" data-se="forgot-password" class="link js-forgot-password">Forgot password?</a>';
  upperForgotPassword.addEventListener('click', (e) => {
    e.preventDefault();
    lowerForgotPassword.click();
  });
  oFormButtonBar.appendChild(upperForgotPassword);
};

const transformLogoImg = function transformLogoImg() {
  const logoImg = document.querySelector('.auth.okta-container .auth-container .auth-org-logo');
  if (logoImg) {
    logoImg.outerHTML = '<img src="https://ok12static.oktacdn.com/fs/bco/1/fs0k6z19kIok8j4QQ5d6" class="auth-org-logo" alt="EJ2 Communications, Inc. d/b/a Flashpoint logo logo" aria-label="EJ2 Communications, Inc. d/b/a Flashpoint logo logo">';
  }
};

const addSAMLButton = function addSAMLButton() {
  if (!document.querySelector('.fp-btn-saml')) {
    return;
  }
  const primaryAuthContainer = document.querySelector('#okta-sign-in.auth-container.main-container .primary-auth-container');
  const authDivider = primaryAuthContainer.querySelector('.auth-divider');
  if (!primaryAuthContainer || !authDivider) {
    return;
  }
  if (primaryAuthContainer.querySelector('.fp-okta-saml-button')) {
    return;
  }
  const samlButton = document.createElement('a');
  samlButton.innerHTML = '\n    <svg width="17.5" height="17.5" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n      <path d="M9.40625 4.9375C9.40625 4.57383 9.69883 4.28125 10.0625 4.28125C10.4262 4.28125 10.7188 4.57383 10.7188 4.9375C10.7188 5.30117 10.4262 5.59375 10.0625 5.59375C9.69883 5.59375 9.40625 5.30117 9.40625 \n      4.9375ZM9.1875 10.625C8.92773 10.625 8.67344 10.6059 8.42461 10.5648L7.65898 11.284C7.56055 11.4316 7.39375 11.5 7.21875 11.5H6.125V12.5938C6.125 12.9574 5.83242 13.25 5.46875 13.25H4.375V14.3438C4.375 14.7074 4.08242 15 3.71875 15H0.65625C0.293945 15 0 14.7074 0 14.3438V11.7188C0 11.5437 \n      0.0691523 11.377 0.192199 11.2539L4.50898 6.93906C4.42148 6.55352 4.375 6.20078 4.375 5.8125C4.375 3.15469 6.52969 1 9.1875 1C11.8453 1 14 3.15469 14 5.8125C14 8.47031 11.8453 10.625 9.1875 10.625ZM9.1875 9.75C11.3613 9.75 13.125 7.98633 13.125 5.8125C13.125 3.63785 11.3613 1.875 9.1875 1.875C7.01367 \n      1.875 5.25 3.63785 5.25 5.8125C5.25 6.13242 5.28828 6.4168 5.33477 6.73398L5.47148 7.2125L0.875 11.809V14.125H3.5V12.375H5.25V10.625H7.12852L8.12109 9.62969L8.56406 9.70078C8.76641 9.73359 8.97422 9.75 9.1875 9.75Z" fill="#7A84A1"></path>\n    </svg>\n    Single Sign On';
  samlButton.href = `${window.location.origin}/saml`;
  samlButton.classList.add('fp-okta-saml-button');
  primaryAuthContainer.insertBefore(samlButton, authDivider.nextSibling);
};

const tryFunctions = function tryFunctions(funcArray) {
  funcArray.forEach((myFunc) => {
    try {
      myFunc();
    } catch (e) {
      // console.error(e);
    }
  });
};

const tryTransformations = function tryTransformations() {
  tryFunctions([setClassForUrlState, transformAuthDividerText, transformIdpDiscoveryButtonText, transformUnlockEmailButtonText, transformGoogleButton, addShowPasswordButton, fixEmailMargin, fixEmailLabel, transformBackLinkText, transformInfoboxError, addClassForErrorState, addInputErrorStateIcon, happensToTheBestOfUs, looksLikeYourAccountHasBeenLocked, unlockEmailHasBeenSent, recoveryEmailHasBeenSent, transformAccountUnlockRequested, expiresInThreeDays, transformLogoSubtext, moveForgotPasswordLink, transformLogoImg, addSAMLButton]);
};

const delayTransformations = function delayTransformations() {
  setTimeout(() => {
    tryTransformations();
  });
};

export default delayTransformations;
