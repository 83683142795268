/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { componentInit } from './componentInit';
import { useRunOnce } from './useRunOnce';
import { setUpStyles, parentStyle } from './oktaSignInPageVisualFunctions';

export interface OktaSignInPageProps {
  className?: string;
  testId?: string;
  error?: string;
  redirectAuthenticatedUser?: VoidFunction | null,
  LoginComponent: React.FunctionComponent,
  oktaAuth: any,
  authState: any,
}

// loginComponent must
// 1. render an OktaSignIn widget
// 2. contain useEffect login which redirects an authenticated user
export const OktaSignInPage = ({
  className,
  testId,
  error,
  redirectAuthenticatedUser,
  LoginComponent,
  oktaAuth,
  authState,
}: OktaSignInPageProps): JSX.Element => {
  const [didInit, setDidInit] = useState(false);
  const confirmDidInit = () => { setDidInit(true); };
  const authStateIsReady = () => authState && !authState?.isPending;
  useEffect(() => setUpStyles(), []);

  // this hook makes many important transformations to the existing okta widget and even
  // to th`e surrounding html generated by the jsx in this component's return statement
  useRunOnce(() => {
    componentInit({ oktaAuth, authState, redirectAuthenticatedUser, confirmDidInit });
  }, [authStateIsReady()]);

  const memoLoginComponent = React.useMemo(() => <LoginComponent />, []);

  return (
    <div
      style={parentStyle(didInit, authState) as React.CSSProperties}
      className="auth okta-container">
      <div
        id="pageContent"
        data-testid={testId}
        className={className}
      >
        <div className="loginHeader">
          <div className="fpFancyVectorLogoText" />
          <div className="signInToContinue">Log in to continue
          </div>
        </div>
        <div className="dropShadowContainer">
          {memoLoginComponent}
          <div className="requestADemo">
            Prefer dark mode?<a target="_blank" href="https://app.flashpoint.io" rel="noreferrer">Login to Ignite</a>
            {error && <div className="error">{error}</div>}
          </div>
        </div>
        <div className="notification"> By using this platform, you agree and shall comply with Flashpoint’s&nbsp;
          <a rel="noreferrer" target="_blank" href="https://flashpoint.io/wp-content/uploads/2022/08/Short-Form-Online-Flashpoint-Subscription-Agreement_08-02-22.pdf">Terms of Service.</a>
        </div>
        <div className="logos">
          <div className="automateLogo" />
          <div className="vulnDbLogo" />
          <div className="echosecLogo" />
        </div>
      </div>
    </div>
  );
};

OktaSignInPage.defaultProps = {
  className: null,
  testId: null,
  error: null,
  redirectAuthenticatedUser: null,
};
