import Token from '../../../utils/token';

/*
 * Custom storage for Okta JWTs.
 *
 * We are using this custom store to handle reading and writing Okta JWTs
 * to localStorage and write our Auth cookie. Whenevever Okta writes the
 * accessToken to localStorage we also write a copy to the
 * 'X-FP-Authorization' cookie. This ensures that our APIs are authenticated
 * properly and that it is refreshed according to Okta settings.
 */

export const OktaStore = {
  removeItem: (key: string) => {
    const store = window.localStorage;
    if (!store) {
      throw new Error('localStorage unavailable');
    }
    return store.removeItem(key);
  },
  getItem: (key: string): string => {
    const store = window.localStorage;
    if (!store) {
      throw new Error('localStorage unavailable');
    }
    return store.getItem(key);
  },
  setItem: (key: string, value: string) => {
    const store = window.localStorage;
    if (!store) {
      throw new Error('localStorage unavailable');
    }
    if (!value || !key) return;
    /* 1. Set local storage */
    store.setItem(key, value);

    if (key === 'okta-token-storage') {
      const tokens = JSON.parse(value);
      if (!tokens) return;

      if (!tokens?.accessToken?.value && !tokens?.accessToken?.expiresAt) {
        throw new Error('Unable to write Okta accessToken cookie');
      }

      // Convert Okta JWT expiration from s to ms
      const expiration = tokens?.accessToken?.expiresAt
        ? new Date(tokens?.accessToken?.expiresAt * 1000)
        : null;
      /* 2. Set the FP jwt cookie */
      Token.set('X-FP-Authorization', tokens?.accessToken?.value, { expires: expiration });
    }
  },
};

export default OktaStore;
