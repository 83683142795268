import React from 'react';

import WebFont from 'webfontloader';
import { Router, Route, Switch } from 'react-router-dom';
import { render } from 'react-dom';
import { RecoilRoot } from 'recoil';
import { Security, LoginCallback } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import GlobalStyles from '@mui/material/GlobalStyles';

import { Theme as StyledTheme } from './shared/theme/Theme';
import necessaryLegacyStylesRoot from './theme/necessaryLegacyStylesRoot';
import necessaryLegacyStylesProps from './theme/necessaryLegacyStylesProps';
import History from './utils/history';
import Theme from './theme';
import Invalid from './components/utils/Invalid/Invalid';
import UniversalAuth from './containers/Auth/UniversalAuth';
import UniversalSamlAuth from './containers/Auth/UniversalSamlAuth';
import { Okta } from './core/utils/okta';
import { createFpTheme } from './theme/createFpTheme';
import './containers/Auth/auth.module.scss';

const oktaAuth = Okta.getAuthManager();

WebFont.load({
  google: {
    families: [
      'Open+Sans:400,600,700',
      'Libre+Franklin:600,700',
      'Montserrat:600,700',
      'Rajdhani:600',
    ],
  },
});

const App = () => (
  <Router history={History}>
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={async (_oktaAuth, originalUri) => {
        History.replace(
          toRelativeUrl(originalUri, window.location.origin),
        );
      }}
    >
      <Switch>
        <Route exact path="/" component={UniversalAuth} />
        <Route exact path="/login/callback" component={LoginCallback} />
        <Route exact path="/saml" component={UniversalSamlAuth} />
        <Route exact render={() => <Invalid inline title="Page not found" />} />
      </Switch>
    </Security>
  </Router>
  );

const FPTheme = () => {
  const mainTheme = createFpTheme(Theme());
  const fpTheme = JSON.parse(localStorage.getItem('fp_theme'));
  if (fpTheme) {
    return (
      <StyledThemeProvider theme={StyledTheme}>
        <App />
      </StyledThemeProvider>
    );
  } else {
    return (
      <RecoilRoot>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={mainTheme}>
            <App />
          </ThemeProvider>
        </StyledEngineProvider>
        <StyledEngineProvider injectFirst>
          <GlobalStyles styles={necessaryLegacyStylesProps} />
        </StyledEngineProvider>
      </RecoilRoot>
    );
  }
};

render(
  <FPTheme/>,
  document.getElementById('auth'),
);
