
import { setOktaSignInSharedStyles } from './setOktaSignInSharedStyles';
import { setOktaSignInPeculiarStyles } from './setOktaSignInPeculiarStyles';
import delayTransformations from './oktaWidgetTransformations';

export const setUpStyles = () => {
  const sharedStyleTag = setOktaSignInSharedStyles();
  const peculiarStyleTag = setOktaSignInPeculiarStyles();
  delayTransformations();
  return () => {
    sharedStyleTag.remove();
    peculiarStyleTag.remove();
  };
};

const shouldShowWidget = (didInit, authState) => didInit && !authState?.isAuthenticated;
const disabledStyle = () => ({
  opacity: '50%',
});
const enabledStyle = () => ({
  opacity: '100%',
});
export const parentStyle = (didInit, authState) =>
  (shouldShowWidget(didInit, authState) ? enabledStyle() : disabledStyle());
