import { fetchAndSetTokensForExistingSession, userIsAuthenticated } from './oktaAuthUtils';

export const componentInit = async (
  { oktaAuth, authState, redirectAuthenticatedUser, confirmDidInit }) => {
  if (await userIsAuthenticated(authState)) {
    // do nothing. sign-in widget should auto redirect
  } else {
    const tokens = await fetchAndSetTokensForExistingSession(oktaAuth);
    // The parent router should auto redirect when starting from origin
    if (tokens) {
      if (redirectAuthenticatedUser) {
        redirectAuthenticatedUser(tokens);
      } else if (oktaAuth.getOriginalUri()) {
        oktaAuth.handleLoginRedirect(tokens);
      } else {
        // eslint-disable-next-line no-undef
        window.location.reload();
      }
    } else {
      // do nothing. sign-in widget will render and user will enter credentials
    }
  }
  confirmDidInit();
};

