import { useEffect, useRef } from 'react';

const noop = () => null;

// This custom hook runs only once when all deps are satisfied
export const useRunOnce = (callback, deps) => {
  const hasRunOnce = useRef(false);
  const allDependenciesSatisfied = myDeps => myDeps.every(a => a);
  const hasNotRunOnce = myRef => !myRef.current;
  useEffect(() => {
    if (hasNotRunOnce(hasRunOnce) && allDependenciesSatisfied(deps)) {
      hasRunOnce.current = true;
      return callback();
    }
    return noop;
  }, deps);
};
