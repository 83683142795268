const styleString = `
  .auth.okta-container {
    left: 0;
    position: fixed;
    top: 0;
    z-index: var(--modalZIndex);
    --raleway: 'Hind';
  }

  #pageContent {
    left: 0;
    position: absolute;
    top: 0;
  }

  div[data-se='CUSTOM_HOTP'] {
    display: none !important;
  }

  #okta-sign-in.auth-container.main-container .primary-auth {
    padding-bottom: 0;
  }
`;

export const setOktaSignInPeculiarStyles = () => {
  const peculiarStyleTag = document.createElement('style');
  peculiarStyleTag.textContent = styleString;
  document.head.append(peculiarStyleTag);
  return peculiarStyleTag;
};
