import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { OktaSignInPage } from '../../components/utils/OktaSignInPage';
import { LoginWidget } from '../../shared/navigation';
import { SsoConfig } from '../../core/utils/okta';
import './auth.module.scss';

export const redirectAuthenticatedUser = () => {
  window.location.href = '/';
};

const UniversalAuth = () => {
  const { oktaAuth, authState } = useOktaAuth();

  return (
    <>
      <OktaSignInPage
        oktaAuth={oktaAuth}
        authState={authState}
        redirectAuthenticatedUser={redirectAuthenticatedUser}
        LoginComponent={() => <LoginWidget config={SsoConfig} />}
    />
      <div className="fixed-commit-hash-indicator">{process.env.VERSION} - {process.env.COMMITHASH}</div>
    </>
  );
};

export default UniversalAuth;
