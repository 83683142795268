import React from 'react';

import ColorHash from 'color-hash';
import chroma from 'chroma-js';
import DOMPurify from 'dompurify';
import moment from 'moment';

import History from './history';

const htmlEntities = {
  nbsp: ' ',
  cent: '¢',
  pound: '£',
  yen: '¥',
  euro: '€',
  copy: '©',
  reg: '®',
  lt: '<',
  gt: '>',
  quot: '"',
  amp: '&',
  apos: '\'',
  ndash: '-',
  rsquo: '’',
  lsquo: '‘',
};

const Text = {
  BalancedString: (text = '') => {
    let parens = 0;
    let doubleQuotes = 0;
    // don't want to check single quotes due to contractions
    for (let i = 0; i < text.length; i += 1) {
      const char = text[Number(i)];
      const prevChar = (i !== 0) ? text[i - 1] : '';
      parens += char === '(' && prevChar !== '\\';
      parens -= char === ')' && prevChar !== '\\';
      if (parens < 0) break;
      doubleQuotes += char === '"' && prevChar !== '\\';
    }
    if (parens !== 0) return 'mismatched parentheses';
    if (doubleQuotes % 2 !== 0) return 'unmatched double quotation marks';
    return true;
  },
  Color: (index = 0, key, isText = false, specificColor) => {
    const baseColors = ['#313638', '#5c6ae0', '#14967A', '#176BA6', '#F4BA0B', '#D92555'];
    const hash = new ColorHash({});
    const { pathname } = History.getCurrentLocation();
    const split = pathname.split('/');
    const colorHash = hash.hex(key || split[split.length - 1]);
    const distances = baseColors.map(v => chroma.distance(colorHash, v));
    const minIndex = distances.reduce((iMin, v, i, arr) => (v < arr[Number(iMin)] ? i : iMin), 0);
    const baseColor = specificColor || baseColors[Number(minIndex)];
    const range = chroma.scale([baseColor, chroma(baseColor).brighten(1).hex(), chroma(baseColor).brighten(2).hex(), chroma(baseColor).brighten(3).hex()]).domain([0, 0.15, 0.4, 1]).mode('lch').colors(10);
    const color = range[Number(index)];
    if (isText) {
      const black = chroma.contrast(color, '#000');
      const white = chroma.contrast(color, '#fff');
      return (black - white > 3) ? '#000' : '#fff';
    }
    return range[Number(index)];
  },
  DefangLink: (link = '') => link
    .replace('http', 'hxxp')
    .replace('.', '[.]'),
  Empty: (text = '') => {
    const html = (Array.isArray(text) ? text[0] : text || '')
      .toString()
      .replace(/\r?\n|\r/g, '');
    const content = DOMPurify.sanitize(html, {
      FORBID_ATTR: ['style', 'http-equiv'],
      FORBID_TAGS: ['img', 'meta'],
      ALLOWED_TAGS: ['div', 'blockquote', 'p', 'span', 'b', 'br'],
    });
    return !content; },
  Export: (html = '') => html
    .replace(/<b>/g, '')
    .replace(/<\/b>/g, '')
    .replace(/<br \/>/g, '////'),
  GlossaryKey: (html = '') => html
    .replace(/<strong>/g, '')
    .replace(/<\/strong>/g, '')
    .replace(/<em>/g, '')
    .replace(/<\/em>/g, '')
    .replace(/[*()]/g, '')
    .replace(/<a.*">/g, '')
    .replace(/<\/a>/g, '')
    .trim(),
  GlossaryValue: (html = '') => html
    .replace(/<a.*">/g, '')
    .replace(/<\/a>/g, ''),
  Highlight: (html = '', whitelist = false, classNameForWrapper) => {
    if (!html || html === '-') return html;
    const text = (Array.isArray(html) ? html[0] : html || '')
      .toString()
      // handle nested x-fp-highights in html tags
      .replace(/<<x-fp-highlight>(.*?)<\/x-fp-highlight>/ig, '<$1')
      .replace(/<\/<x-fp-highlight>(.*?)<\/x-fp-highlight>/ig, '</$1')
      .replace(/<x-fp-highlight>/ig, '<span class="highlight">')
      .replace(/<\/x-fp-highlight>/ig, '</span>');

    const content = whitelist
      ? text
      : DOMPurify.sanitize(text,
        {
          FORBID_ATTR: ['style', 'http-equiv'],
          FORBID_TAGS: ['img', 'meta'],
          ALLOWED_TAGS: ['div', 'blockquote', 'p', 'span', 'b', 'br'],
        });
    return <div className={classNameForWrapper} dangerouslySetInnerHTML={{ __html: content }} />; },
  HighlightColor: html => html.replace(/#ff9900/ig, '#5c6ae0'), // replace gdoc inline styling color to blurple
  Market: (html = '') => (html || '')
    .toString()
    .replace(/(\r\n|\n|\r)/gm, '<br />')
    .replace(/<<x-fp-highlight>(.*?)<\/x-fp-highlight>>/gm, '<$1>'),
  Post: (html = '') => html
    .toString()
    .replace(/(\r\n|\n|\r){3,}/gm, '<br /><br />')
    .replace(/(\r\n|\n|\r)/gm, '<br />')
    // eslint-disable-next-line security/detect-unsafe-regex
    .replace(/(<br>|(<br>\s+)){3}/g, '')
    .replace(/<meta/gi, ' meta')
    .replace(/\(.*?\)\s&gt;&gt;[0-9]{5,}/gim, '')
    .replace(/<div>(&nsbp;|\u00a0)<\/div>/g, '')
    .replace(/<blockquote><div><\/div><\/blockquote>,?/g, '')
    .replace(/<\/div>,/g, '</div> ')
    .replace(/<div><div>Quote:/g, '<div class="quote"><div>Quote:')
    .replace(/<strong>(.*?)<\/strong>/g, '$1')
    .replace(/<<x-fp-highlight>(.*?)<\/x-fp-highlight>>/gm, '<$1>'),
  Links: (html = '') => html
    .replace(/href="(.*?)<x-fp-highlight>(.*?)<\/x-fp-highlight>(.*?)"/gm, 'href="$1$2$3"')
    .replace(/(href=".*?)(%26)(.*?")/gm, '$1&$3')
    .replace(/(href=".*?)(%3D)(.*?")/gm, '$1=$3')
    .replace(/<a\s/gm, '<a rel="noopener noreferrer" target="_blank" '),
  SanitizeLinks: (html = '') => (html || '')
    .toString()
    .replace(/<img/gm, '<pre>&lt;img')
    .replace(/<\/img/gm, '</pre>&lt;/img')
    .replace(/<a/gm, '<pre>&lt;a')
    .replace(/<\/a/gm, '</pre>&lt;/a'),
  SHA256: (string = '', chars = null) => {
    // encode as UTF-8
    const msgBuffer = new TextEncoder('utf-8').encode(string);

    // hash the message
    return new Promise((resolve) => {
      crypto.subtle.digest('SHA-256', msgBuffer).then((hashBuffer) => {
        // convert ArrayBuffer to Array
        const hashArray = Array.from(new Uint8Array(hashBuffer));

        // convert bytes to hex string
        let hashHex = hashArray.map(b => (`00${b.toString(16)}`).slice(-2)).join('');
        if (chars) hashHex = hashHex.slice(0, chars);
        resolve(hashHex);
      });
    }); },
  SFIDConversion: (sfid) => {
    if (!sfid) return { 15: '', 18: '' };
    if (sfid.length === 15) {
      let addon = '';
      for (let block = 0; block < 3; block += 1) {
        let loop = 0;
        for (let position = 0; position < 5; position += 1) {
          const current = sfid.charAt((block * 5) + position);
          if (current >= 'A' && current <= 'Z') loop += 1 << position;
        }
        addon += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ012345'.charAt(loop);
      }
      return { 15: sfid, 18: sfid + addon };
    } else if (sfid.length === 18) {
      return { 15: sfid.slice(0, 15), 18: sfid };
    }
    return { 15: sfid, 18: sfid }; },
  Standup: (html = '') => html
    .replace(/(\r\n|\n|\r)/gm, '')
    .replace(/<p>\s+?<\/p>/gm, '')
    .replace(/<\/a>&nbsp;\(/gm, '')
    .replace(/<p dir="ltr">(Additional|See also).*?<\/p>/g, '')
    .replace(/<p dir="ltr">Please find past standups.*?<\/p>/g, '')
    .replace(/<p( dir="ltr")?>[\u202F\u00A0]<\/p>/g, '')
    .replace(/<p( dir="ltr")?>(<em>)?===.*?$/g, '')
    .replace(/<li.*?<\/li>/gm, match => (match.match(/<strong>Summary.*?<\/strong>/g) ? match : ''))
    .replace(/<ul( dir="ltr")?><li.*?>((<p)?.*?<\/strong>(.*?)(<\/p>)?)<\/li><\/ul>/gm, (p1, p2, p3, p4, p5) => {
      let trimmed = p5.substring(0, 150);
      trimmed = p5.substr(0, Math.min(trimmed.length, trimmed.lastIndexOf(' ')));
      const replacement = p3.replace(p5, `<em>${trimmed} [...]</em>`);
      return (p3.indexOf('<p') !== -1) ? replacement : `<p dir="ltr">${replacement}</p>`;
    }),
  Style: (html = '') => {
    const styles = /<style.*?>(.*?)<\/style>/.exec(html.toString());
    if (styles) {
      const inlineStyles = styles[1].replaceAll(/}([a-z.])/g, '}.inline $1');
      const fullHtml = html.toString()
        .replace(styles[1], inlineStyles);
      return fullHtml;
    }
    return html;
  },
  GoogleStandup: (html = '') => {
    try {
      const headers = html.match(/(<h3.*?<\/h3>)/gm);
      const targets = html.match(/Target audience:\s?<\/span>(.*?)<\/p>/g);
      let summaries = html.match(/Summary:\s?<\/span>(.*?)<\/p>/g);

      summaries = summaries.map((v) => {
        const summary = /Summary:?.?<\/span>(.*)/.exec(v)[1];
        let full = summary.replace(/<span.*?>(<span.*?>)(.*)(<\/span>)/g, '$2');
        if (full.includes('<span')) {
          // Handle cases that don't have an extra span
          full = summary.replace(/(<span.*?>)(.*)(<\/span>)/g, '$2');
          let trimmed = full.substring(0, 300);
          trimmed = full.substr(0, Math.min(trimmed.length, trimmed.lastIndexOf(' ')));
          return summary.replace(/(<span.*?>)(.*)(<\/span>)/g, `<div>$1${trimmed.replace(/\$/g, '&#36;')} [...]$3</div>`);
        }
        let trimmed = full.substring(0, 300);
        trimmed = full.substr(0, Math.min(trimmed.length, trimmed.lastIndexOf(' ')));
        return summary.replace(/<span.*?>(<span.*?>)(.*)(<\/span>)/g, `<div>$1${trimmed.replace(/\$/g, '&#36;')} [...]$3</div>`);
      });

      return `
        <div>
          ${headers.map((v, k) => `
            <div>
              <h3><span><a>${v?.replace(/<[^>]+>/g, '')}</a></span></h3>
              <span>${targets[Number(k)]?.replace(/Target audience:/ig, '')?.replace(/<[^>]+>/g, '')}</span>
              <span>${summaries[Number(k)] ? summaries[Number(k)]?.replace(/<[^>]+>/g, '') : ''}</span>
            </div>
          `).join('')}
        </div>
      `;
    } catch (err) {
      return `
      <div style="text-align:center;">
        Unable to show standup preview.
      </div>
      `;
    } },
  ReplaceSmartChars: (html = '') => html
    .replace(/[\u2018\u2019]/g, "'")
    .replace(/[\u201C\u201D]/g, '"')
    .replace(/[\u2013\u2014]/g, '-')
    .replace(/\u2026/g, '...'),
  Report: (html = '') => html
    .replace(/style=".*?"/g, '')
    .replace(/\/api\/v4\/assets\/(.*?)"/g, '/ui/v4/assets/$1"')
    .replace(/<table(.*?)>/g, '<table>')
    .replace(/<td(.*?)>/g, '<td>')
    .replace(/<a href="(.*?)"><strong>(.*?)<\/strong>/g, '<a href="$1">$2</a>')
    .replace(/<a href="(.*?)<x-fp-highlight>(.*?)<\/x-fp-highlight>(.*?)"/g, '<a href="$1$2$3">')
    .replace(/<strong>\s+?<\/strong>/, ' ')
    .replace(/<strong>(.*?)<\/strong>(.*?)<br\/>/g, '<div class="row"><strong class="label">$1</strong><div>$2</div></div><br />')
    .replace(/<br \/>\s+<strong>(.*?)<\/strong>(.*?)<\/p>/g, '<br /><div class="row"><strong class="label">$1</strong><div>$2</div></div></p>')
    .replace(/https:\/\/www.google.com\/url\?q=/g, '')
    .replace(/<p>===/g, '<p class="footnote">===')
    .replace(/<p><em>===/g, '<p class="footnote"><em>===')
    .replace(/(=======.*=======.*?)(<p.*)/, '$1'),
  GoogleLinks: (html = '') => html
    .toString()
    .replace(/https:\/\/www.google.com\/url\?q=/g, '')
    .replace(/&amp;sa=D&amp;ust=\d{16}?/g, '')
    .replace(/href="(https:\/\/fp.tools\/.*?)"/gi, (l1, l2) =>
      `href="${decodeURIComponent(l2)}"`),
  Strip: (html = '') => (html || '')
    .toString()
    .replace(/<x-fp-highlight>/g, '')
    .replace(/<\/x-fp-highlight>/g, ''),
  StripCsv: (text = '') => (text || '')
    .toString()
    .replace(/<\/?x-fp-highlight>/g, '')
    .replace(/[\n\\]/g, '')
    .replace(/,/g, '')
    .replace(/'/g, '')
    .replace(/\s+/g, ' ')
    .replace('', '')
    .trim(),
  StripHtml: (html = '', whitelist = false) => {
    if (!html || html === '-') return html;
    // if html is the number 0, it will read as false with html || '' so need to
    // explicitly check for that case
    const text = ((typeof html === 'number' ? html : html || '')
      .toString()
      .replace(/<<x-fp-highlight>(.*?)<\/x-fp-highlight>>/gm, '<$1>')
      .replace(/<(\/)?x-fp-highlight>/gm, '::$1highlight')
      .replace(/<(\/)?x-fp-internal-links>/g, '::$1links')
      .replace(/<(\/)?x-fp-internal-link href=(\S+)>\b/g, '::link::href$2::/href')
      .replace(/<(\/)?x-fp-internal-link ?>/g, '::$1link'));
    const content = whitelist
      ? text
      : DOMPurify.sanitize(text || '',
        {
          FORBID_ATTR: ['http-equiv'],
          FORBID_TAGS: ['meta'],
          ALLOWED_TAGS: ['div', 'blockquote', 'p', 'span', 'b', 'br'],
        });
    return content
      .replace(/::highlight/g, '<x-fp-highlight>')
      .replace(/::\/highlight/g, '</x-fp-highlight>')
      .replace(/::links/g, '<div class="links">Links in post found on FP Tools')
      .replace(/::\/links/g, '</div>')
      .replace(/::link::href(\S+)::\/href/g, '<a href=$1 target="_blank">')
      .replace(/::\/link/g, '</a>')
      .trim(); },
  ReportCard: (html = '') => html
    .replace(/^(?!")<a.*?>(.*?)<\/a>([^"]?)$/ig, '') // match <a> unless they are in ""
    .replace(/(skip|go|back)\sto.*?<span.*?<\/span>/ig, '')
    .replace(/<html>(.*?)<body.*?>/ig, '')
    .replace(/<h[125].*?>(.*?)<\/h[125]>/ig, '')
    .replace(/<[^>]+>/g, '')
    .replace(/\[\]/g, ''),
  Sentence: (text = '') => ((/\w-\w/.test((text || '').toString())
    ? text
    : (text || ''))
    .toString()
    .toLowerCase()
    .replace(/[_]/gm, ' ')
  // Use this regex to capitalize the first letter of each word. Handles words
  // wrapped in other characters
    .replace(/(?:^|\s|["'([{-])+\S/g, match => match.toUpperCase())
    .split(' ')
    .map(v => v.replace(/^(cves|iocs|urls)/ig, match => `${match.substr(0, (match.length - 1)).toUpperCase()}s`))
    .map(v => v.replace(/(^|[(-\s])(^as[0-9]+$|^os$|^md5$|^sha\d{1,}$|^ssdeep$|^tr1$|^tr2$|^ccm$|^ips$|^uk$|^ios$|^dprk$|^cpe$|^xor$|^usb$|$^smtp$|^ftp$|^pop3$|apt|cvssv2|cvssv3|cwe|eula|att&ck|asn|ioc|uuid|ddw|fqdn|ttps|isp|us|php|uri|http|mit|ip|id|cve|utc|mitre|nvd|tfa|ccv|cvv)(?=[)-\s]|$)/ig, match => match.toUpperCase()))
    .join(' ')),
  Translate: (text = '') => (text || '')
    .replace(/Home/gm, '<br>')
    .replace(/<div>\s+<\/\s?div>/, '')
    .replace(/<blockquote>(\s?)<br\/>(\s?)<br\/>/, '<blockquote>')
    .replace(/<br\/>(\s?)<br\/>(\s?)<\/\s?blockquote>/, '</blockquote>')
    .replace(/<\/\s/gm, '</'),
  StripHighlight: (text = '') => (text || '')
    ?.replace(/<x-fp-highlight>/ig, '')
    ?.replace(/<\/x-fp-highlight>/ig, ''),
  StripCss: (html = '') => (html || '')
    .replace(/<style type="text\/css">.*<\/style><\/head>/g, '')
    .replace('KEY TAKEAWAYS', '')
    .replace('OUTLINE', '')
    .replace('DETAILS', ''),
  StripQuotes: (html = '') => (html || '')
    .replace(/"/g, '&#34;')
    .replace(/'/g, '&#39;'),
  Trim: (text = '', numChars = 150) => {
    const highlightIndex = text.search(/<x-fp-highlight>/ig);
    let startTrimIndex = 0;
    let endTrimIndex = numChars;
    let totalLength = numChars;
    // Trim around the highlighted word up to the number of characters
    // If the highlighted string is closer to the beginning, add additional
    // characters to the end of the trimmed string. If it is closer to the end,
    // add additional characters to the beginning of the trimmed string.
    if (highlightIndex !== -1 && text.length > totalLength) {
      const endHighlightIndex = text.search(/<x-fp-highlight>/ig);
      // + 16 is for <x-fp-highlight>
      const highlightLength = endHighlightIndex - (highlightIndex + 16);
      const remainingChars = numChars - highlightLength;
      const halfLength = remainingChars / 2;
      let extraEndChars = 0;
      let extraStartChars = 0;

      // Determine if there are available characters to be added to the end
      if (highlightIndex - halfLength >= 0) {
        startTrimIndex = highlightIndex - halfLength;
      } else {
        startTrimIndex = 0;
        extraEndChars = halfLength - highlightIndex;
      }

      // + 17 is for </x-fp-highlight>
      // Determine if there are available characters to be added to the front
      if (endHighlightIndex + 17 + halfLength <= text.length) {
        endTrimIndex = endHighlightIndex + 17 + halfLength;
      } else {
        endTrimIndex = text.length;
        extraStartChars = (endHighlightIndex + 17 + halfLength) - text.length;
      }

      if (extraStartChars > 0) {
        startTrimIndex = Math.max(startTrimIndex - extraStartChars, 0);
      }
      if (extraEndChars > 0) {
        endTrimIndex = Math.min(endTrimIndex + extraEndChars, text.length);
      }
      // char count of <x-fp-highlight></x-fp-highlight>
      totalLength = numChars + 33;
    }
    // We don't want to split a piece of a word during substring, so find the
    // closest space before or after the start/end index
    startTrimIndex = Math.min(startTrimIndex, text.lastIndexOf(' ', startTrimIndex));
    endTrimIndex = Math.max(endTrimIndex, text.indexOf(' ', endTrimIndex));
    // If the text is one long string, we want there to be a maximum length even
    // if it means cutting a word in a piece - sometimes a paste is one long string
    // with no spaces
    if (endTrimIndex - startTrimIndex > numChars * 1.5) {
      endTrimIndex = startTrimIndex + (numChars * 1.5);
    }
    const trimmed = text.substring(startTrimIndex, endTrimIndex);
    return `${trimmed}${text.length > totalLength ? ' [...]' : ''}`; },

  AbbreviateNumber: (num = '', toThousandths = true) => {
    const n = parseInt(num, 10);
    if (Number.isNaN(n)) return num;
    const fmt = v => new Intl.NumberFormat().format(Math.round((v * 10) / 10));
    if (n >= 1000000000) return `${fmt(n / 1000000000)}B`;
    if (n >= 1000000) return `${fmt(n / 1000000)}M`;
    if (toThousandths && n >= 1000) {
      return `${fmt(n / 1000)}k`;
    } else if (n >= 1000) {
      return `${fmt(n / 100)}00`;
    }
    return n ? fmt(n) : '0'; },
  ExtractReply: (text = '') => (text || '')
    .replace(/&gt;/gm, '>')
    .replace(/(?:.*?(?:^|\s)?)(>>[0-9]+?)([a-zA-Z>\s])/gm, '$1<br/>$2'),
  BlurBack: (t, blurTrue = false, num_blur = 2) => {
    if (!blurTrue || !t) return t;
    if (t.length < num_blur) return Text.BlurAll(t, blurTrue);
    return t.substring(0, num_blur) + '*'.repeat(t.length - num_blur);
  },
  ab2str: (buffer = []) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i += 1) binary += String.fromCharCode(bytes[Number(i)]);
    return btoa(binary);
  },
  str2ab: (str = '') => {
    const binary_string = atob(str);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i += 1) bytes[Number(i)] = binary_string.charCodeAt(i);
    return bytes.buffer;
  },
  Filesize: (bytes = 0) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const int = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10) || 0;
    return `${Math.round(bytes / (1024 ** int), 2)} ${sizes[Number(int)]}`;
  },
  BlurFront: (t, blurTrue = false, num_blur = 2) => {
    if (!blurTrue || !t) return t;
    return '*'.repeat(t.length - num_blur) + t.substring(t.length - num_blur, t.length);
  },
  BlurAll: (t, blurTrue = false) => {
    if (!blurTrue || !t) return t;
    return '*'.repeat(t.length);
  },
  BlurDomain: (t, blurTrue = false) => {
    if (!blurTrue || !t) return t;
    // eslint-disable-next-line prefer-destructuring
    const text = t.trim().includes(' ') ? t.split(' ')[0] : t;
    let blurred = '';

    if (text.includes('.')) {
      const dot_index = text.search(/\.\w+$/);
      const domain_name = text.substring(0, dot_index).trim();
      const topLevelDomain = text.substring(dot_index);
      blurred = `${Text.BlurAll(domain_name, blurTrue)}${topLevelDomain}`;
    } else {
      blurred = Text.BlurAll(text, blurTrue);
    }
    return blurred;
  },
  BlurEmail: (t, blurTrue = false) => {
    if (!blurTrue || !t || !t.includes('@')) return t;
    const [handle, domain] = t.split('@');
    // in some cases, there is an id at the end of the domain, preceeded by ':'
    const domainIdCombo = domain.split(':');
    const domain_str = Text.BlurDomain(domainIdCombo[0], blurTrue);
    return `${Text.BlurBack(handle, blurTrue)}@${domain_str}\
            ${domainIdCombo[1] ? `:${Text.BlurBack(domainIdCombo[1], blurTrue)}` : ''}`;
  },
  BlurPassword: (t, blurTrue = false) => {
    if (!blurTrue || !t) return t;
    if (typeof t !== 'string') return t;
    const middle = t.substring(1, t.length - 1);
    return t[0] + Text.BlurAll(middle, blurTrue) + t[t.length - 1];
  },
  BlurFirstDomainInline: (t, blurTrue = false) => {
    if (!blurTrue || !t) return t;
    const match = t.match(/(\w+\.\w+)(.+\|.+)/);
    if (!match || !match[1] || !match[2]) return t;
    return `${Text.BlurDomain(match[1], blurTrue)}${match[2]}`;
  },
  BlurOrg: (t, blurTrue = false) => {
    if (!blurTrue || !t) return t;
    let blurred = '';
    // Org names that start with numbers are easily identified
    if (t.charCodeAt(0) >= 48 && t.charCodeAt(0) <= 57) {
      blurred = Text.BlurAll(t, blurTrue);
    } else {
      blurred = Text.BlurBack(t, blurTrue, 1);
    }
    return blurred;
  },
  stripEntities: str => str.replace(/&([^(;|&)]+);/g, ''),
  slowlyUnescapeHTMLEntities: (() => {
    // this prevents any overhead from creating the object each time
    const element = document.createElement('div');

    const decodeHTMLEntities = (str) => {
      let decodedStr = str;
      if (str && typeof str === 'string') {
        // strip script/html tags
        // eslint-disable-next-line security/detect-unsafe-regex
        decodedStr = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
        // eslint-disable-next-line security/detect-unsafe-regex
        decodedStr = decodedStr.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
        element.innerHTML = decodedStr;
        decodedStr = element.textContent;
        element.textContent = '';
      }

      return decodedStr;
    };

    return decodeHTMLEntities;
  })(),
  unescapeHTML: (str) => {
    const unescapedStr = str.replace(/&([^(;|&)]+);/g, (entity, entityCode) => {
      let match;

      if (entityCode in htmlEntities) {
        return htmlEntities[String(entityCode)];
        /* eslint no-cond-assign: 0 */
      } else if (match = entityCode.match(/^#x([\da-fA-F]+)$/)) {
        return String.fromCharCode(parseInt(match[1], 16));
        /* eslint no-cond-assign: 0 */
      } else if (match = entityCode.match(/^#(\d+)$/)) {
        return String.fromCharCode(~~match[1]);
      }
      return Text.slowlyUnescapeHTMLEntities(entity);
    });
    return unescapedStr;
  },
  timeDiff: (startDate, endDate) => {
    const convertToMoment = (date) => {
      if (!date) return null;
      let momentDate = date;
      if (typeof date === 'string' || date instanceof Date) {
        momentDate = moment(date);
      }
      if (momentDate instanceof moment !== true || momentDate.invalid) return null;
      return momentDate;
    };

    const start = convertToMoment(startDate);
    const end = convertToMoment(endDate);
    if (!start || !end) return null;

    const years = end.diff(start, 'years');
    start.add(years, 'years');

    const months = end.diff(start, 'months');
    start.add(months, 'months');

    const days = end.diff(start, 'days');
    start.add(days, 'days');

    const hours = end.diff(start, 'hours');
    start.add(hours, 'hours');

    const minutes = end.diff(start, 'minutes');
    start.add(minutes, 'minutes');

    const seconds = end.diff(start, 'seconds');

    return {
      years,
      months,
      days,
      hours,
      minutes,
      seconds,
    };
  },
  getUsernameFromEmail(username = '') {
    // Some users have an email as their username.
    // We only want the username part
    return username.includes('@')
      ? username?.split('@')?.[0]
      : username;
  },
  SanitizeCsv: (text = '') => (text || '')
    .toString()
    .replace(/<\/?x-fp-highlight>/g, '')
    .replace(/^([=@\-+"])/g, "' $1")
    .replace(/[\n\\]/g, '')
    .replace(/,/g, '')
    .replace(/\s+/g, ' ')
    .replace('', '')
    .trim(),
  DetectLanguage: (text = '') => {
    const en = /^[\s\w\d\?><;,\{\}\[\]\\(\)-_\+=!@\#\$%^&\*\|\'\"]*$/ig;
    if (!text) return '';
    return en.test(text) ? 'en' : '';
  },
};

export default Text;
